<template>
    <div class="eatKitPlaceSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @saveClick="saveData()"  
                @outputClick="exportExcel()" 
                @refreshClick="loadData()" 
                @copyClick="$refs.itemOnKds?.copyShow()"
                @hideClick="hideClick()"
                @showClick="showClick()"
                @goBackClick="$router.back()"/>
            <span>设置方式切换: &nbsp;</span>
            <el-select v-model="showType" default-first-option>
                <el-option :value="0" label="菜品关联出单位置设置"></el-option>
                <el-option :value="1" label="菜品关联打印类型设置"></el-option>
                <el-option :value="2" label="菜品关联KDS设置"></el-option>
                <el-option :value="3" label="KDS关联菜品设置"></el-option>
            </el-select>
        </div>
        <div class="data-box">
            <div class="flex-box" v-show="showType==0">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table ">
                    <div class="filter-box">
                        <div class="from-box" style="margin-left:15px">
                            <el-checkbox v-model="isNotShowTc" label="不展现套餐头菜品" ></el-checkbox>
                        </div>
                    </div>
                    <div class="table-box grey-table">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            ref="tableEl"
                            border
                            highlight-current-row
                            @selection-change="selectFood"
                            @cell-dblclick="dblclick"
                           
                            :data="pageTableData"
                            :cell-class-name="setClassName"
                        >
                           <el-table-column label="选择" type="selection" width="40" ></el-table-column>
                            <el-table-column prop="Food_Code" label="编码" min-width="100" align="left">
                                <template #default="scope">
                                    <span v-copyText>{{scope.row.Food_Code}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Food_Name" label="名称" min-width="100" align="left">
                                <template #default="scope">
                                    <span v-copyText>{{scope.row.Food_Name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Food_Price" label="单价" min-width="80" align="right"></el-table-column>
                            <el-table-column prop="Food_Size" label="单位" min-width="80" align="left"></el-table-column>
                            <el-table-column prop="Kit_Level" label="菜品等级" min-width="80" ></el-table-column>
                            <el-table-column label="上级分类" min-width="80" >
                                <template #default="scope">
                                    {{getCategory(scope.row)}}
                                </template>
                            </el-table-column>
                            <el-table-column label="现出单位置" min-width="100">
                                <template #default="scope">
                                    {{getKitIssuePlace(scope.row)}}
                                </template>
                            </el-table-column>
                            <el-table-column label="更改为出单位置" min-width="130">
                                <template #header>
                                    <el-dropdown trigger="click" :max-height="408">
                                        <span class="el-dropdown-link" >
                                        更改为出单位置<span class="kit_batch">[批量]</span>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <template v-if="KitIssuePlace?.length>0">
                                                    <el-dropdown-item v-for="item in KitIssuePlace" :key="item" @click="kitBatch(item)">{{item.IssuePlace_Name}}</el-dropdown-item>
                                                </template>
                                                <template v-else>
                                                    <el-dropdown-item disabled>无数据</el-dropdown-item>
                                                </template>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                                <template #default="scope">
                                    <el-select v-model="editFoodList[scope.row.Food_ID]" @mousedown="(e)=>e.preventDefault()" @change="rowSelect(scope.row)" >
                                        <el-option :value="item.IssuePlace_AutoID" :label="item.IssuePlace_Name" v-for="item in KitIssuePlace" :key="item"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                                isBlurClose
                        ></key-board-input>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <item-on-print-set class="flex-box" v-show="showType==1"
                :foodMainCategory="foodMainCategory"
                :KitIssuePlace="KitIssuePlace"
                :KitIssuingType="KitIssuingType"
                :PosFoods="PosFoods"
                :printHideXfIDList="hideXfIDList"
                @hideNumber="(num)=>hideNumber=num"
                ref="itemOnPrint"
            ></item-on-print-set>
            <item-on-kds-set class="flex-box" v-show="showType==2"
                :foodMainCategory="foodMainCategoryNotKds"
                :KitIssuePlace="KitIssuePlace"
                :KitPostEquipments="kitPostEqFilter"
                :PosFoods="posFoodsNotKds"
                ref="itemOnKds"
            ></item-on-kds-set>
            <kds-on-item-set  class="flex-box" v-show="showType==3"
                :foodMainCategory="foodMainCategoryNotKds"
                :KitIssuePlace="KitIssuePlace"
                :KitPostEquipments="kitPostEqFilter"
                :PosFoods="posFoodsNotKds"
                ref="kdsOnItem"
            ></kds-on-item-set>
        </div>
        <set-meal-model :isShow="isTcShow" @closeModel="isTcShow=false" :setMealInfo="setMealInfo"></set-meal-model>
        <!-- 查看 隐藏菜品 -->
        <modal-load :isShow="itemHideIsShow">
            <item-hide-model :isShow="itemHideIsShow" @close="itemHideIsShow=false" :hideItem="printHideFoods" @updateItem="(d)=>hideXfIDList=d"></item-hide-model>
        </modal-load>
    </div>
</template>

<script>
import {deepClone,newGuid} from './../../../../common/index';
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import itemOnPrintSet from './itemOnPrintSet.vue';
import itemOnKdsSet from './itemOnKdsSet.vue';
import KdsOnItemSet from './kdsOnItemSet.vue';
import setMealModel from './setMealModel/setMealModel.vue';
import itemHideModel from './itemHideModel/itemHideModel.vue'
export default {
    components: { 
        itemOnPrintSet,
        itemOnKdsSet, 
        KdsOnItemSet,
        setMealModel,
        itemHideModel
    },
    name:"eatKitPlaceSet",
    data() {
        let pageSize= this.$cacheData.get("eatKitPlaceSet_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            /**自定义菜品 */
            PosDiyFoodsData:null,
            foodMainCategory:null,
            tablePageIndex:0,
            pageSize:pageSize,
            //varietySelect:"",
            //搜索数据
            searchTxt:"",
            //出单位置数据
            KitIssuePlace:[],
            /**修改的数据 */
            editFoodList:{},
            /**所有菜品 */
            PosFoods:[],
            showType:0,
            //出单类型 数据
            KitIssuingType:[],
            //站点设备
            posEquipmentList:[],
            //KDS岗位设备信息
            KitPostEquipments:[],
            /**套餐 模板 */
            isTcShow:false,
            /**套餐数据 */
            setMealInfo:null,
            /**高亮 */
            currentIndex:null,
            /**隐藏的菜品数量 */
            hideNumber:0,
            /**是否不显示套餐菜品 */
            isNotShowTc:false,
            /**勾选要修改的菜品 */
            checkFoods:[],
            /**需要隐藏的菜品id */
            hideXfIDList:[],
            /**查看 隐藏菜品 弹层 */
            itemHideIsShow:false,
        }
    },
    computed:{
        /**按钮控制  */
        bnts(){
            let data=["save","refresh","goBack"];
            if(this.showType==2){//一键复制
                data.push("copy");
            }else if(this.showType==0){//输出
                data.push("output");
                data.push("hide");
                data.push({key:"show",num:this.hideXfIDList.length});
            }else if(this.showType==1){//批量隐藏 显示查看
                data.push("hide");
                data.push({key:"show",num:this.hideXfIDList.length});
            }
            return data;
        },
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        label:(sub.SubCategory_Code+"-"+sub.SubCategory_Name).replace(/^[-]/,""),
                        SubCategory_ID:sub.SubCategory_ID,
                    })
                });
                
                tree.push({
                    id:category.MainCategory_ID,
                    label:(category.MainCategory_Code+"-"+category.MainCategory_Name).replace(/^[-]/,""),
                    children:children
                });
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            if(!this.currentNode){
                return this.PosFoods;
            }
            else if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    category.FoodSubCategorys?.forEach(f=>{
                        foods=foods.concat(f.PosFoods);
                    });
                }
                return foods;
            }
            return this.PosFoods.filter(t=>t.SubCategory_ID==this.currentNode.id);
        },
        /**过滤要隐藏的菜品 */
        tableShowFilter(){
            let data=this.tableData||[];
            return data.filter(it=>this.hideXfIDList.indexOf(it.Food_ID)<0);
        },
        filterTableData(){
            let data=this.tableShowFilter||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.isNotShowTc){
                data=data.filter(it=>!it.SetMealInfo);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterTableData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.tableData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        },
        /**过滤不需要的 kds 设备 */
        kitPostEqFilter(){
            let data=[];
            if(this.KitPostEquipments?.length>0 && this.posEquipmentList?.length>0){
                this.KitPostEquipments?.forEach(it=>{
                    it.KdsProjects.forEach(kds=>{
                        let eq=this.posEquipmentList.find(eq=>eq.Equipment_ID==kds.Equipment_ID);
                        if(eq){
                            kds.Equipment_Name=eq.Equipment_Name;
                        }
                    })

                    data.push({
                        KitPostID:it.KitPostID,
                        KitPostEnum:it.KitPostEnum,
                        KitPostName:it.KitPostName,
                        KdsProjects:it.KdsProjects.filter(kds=>kds.Equipment_Name && kds.KdsProjectDetails?.length>0)
                    })
                })
            }
            return data;
        },
        /**排除KDS不显示本菜 Is_KdsNotShow */
        foodMainCategoryNotKds(){
            return this.foodMainCategory?.map(it=>{
                return Object.assign({},it,{
                    FoodSubCategorys:it.FoodSubCategorys?.map(sub=>{
                        return Object.assign({},sub,{
                            PosFoods:sub.PosFoods.filter(item=>!item.Is_KdsNotShow)
                        })
                    })
                })
            })
        },
        /**所有菜品 排除KDS不显示本菜 Is_KdsNotShow */
        posFoodsNotKds(){
            let data= this.PosFoods?.filter(item=>!item.Is_KdsNotShow);
            return data;
        },
        /**要隐藏的菜品数据 */
        printHideFoods(){
            let data=[];
            if(this.hideXfIDList?.length>0){
                this.PosFoods?.forEach(it=>{
                    if(this.hideXfIDList.indexOf(it.Food_ID)>=0){
                        data.push(it);
                    }
                })
            }
            return data;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatKitPlaceSet_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        showType(newVal){
            if(newVal==1){
                this.$refs.itemOnPrint?.loadData();
            }else if(newVal==2){
                this.$refs.itemOnKds?.loadData();
            }else if(newVal==3){
                this.$refs.kdsOnItem?.loadData();
            }
        },
        pageTableData:{
            handler(){
                /**更新 可修改的数据记录 */
                let list={};
                this.pageTableData.forEach(it=>{
                    list[it.Food_ID]=it.KitPlace_ID;
                });
                this.editFoodList=list;
            },
            immediate:true,
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        //出单类型
        this.$cacheData.KitIssuingTypes().then(d=>{
            this.KitIssuingType=d;
        }).catch((e)=>{
            console.log('出单类型获取失败',e)
        })

        //KDS设备
        this.$cacheData.PosEquipments().then((d)=>{
            if(d){
                this.posEquipmentList=d;//.filter(it=>it.Sys_ID==803);
                
            }
        }).catch((e)=>{
            this.$message.error('加载站点数据失败：'+e.message);
            console.log('加载站点数据失败：',e);
        })
        
        this.PosDiyFoodsData=null;
        /**自定义菜品数据结构 */
        this.$cacheData.PosDiyFoods().then((d)=>{
            this.PosDiyFoodsData=deepClone(d||[]);
            this.setPosFoods();
        }).catch(e=>{
            this.setPosFoods();
            this.$message.error('未找到自定义菜品数据'+e.message);
            console.log('e:'+e)
        })

        this.loadScreenPlan();
        this.$nextTick(()=>{
            this.loadData();
            this.loadHideFoodIDs(true);
        })
        
    },
    methods:{
        /**查看套餐 */
        showTc(info){
            this.isTcShow=true;
            this.setMealInfo=info;
        },
        //设置单元格类名
        setClassName({ row, column }){
            if(column.property=="Food_Name" && row.SetMealInfo){
                return "tc";
            }
        },
        loadData(){
            if(this.showType==0){
                this.loadKitIssue();
                this.loadHideFoodIDs(true);
            }else if(this.showType==1){
                this.$refs.itemOnPrint?.loadData(true);
                this.loadHideFoodIDs(true);
            }else if(this.showType==2){
                this.loadScreenPlan();
                this.$refs.itemOnKds?.loadData(true);
            }else if(this.showType==3){
                this.loadScreenPlan();
                this.$refs.kdsOnItem?.loadData(true);
            }
        },
        /**加载出单位置数据 */
        loadKitIssue(){
            //出单位置数据
            this.$cacheData.KitIssuePlace(true).then((d)=>{
                this.KitIssuePlace=d;
            }).catch(e=>{
                this.$message.error("出单位置基础数据获取失败");
                console.log('出单位置基础数据获取失败e:',e)
            })
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.AllPosFoods().then((d)=>{
                loading.close();
                this.currentNode=null;
                this.foodMainCategory=deepClone(d||[]);
                console.log(d)
                this.setPosFoods();
            }).catch(e=>{
                loading.close();
                console.log('e:'+e)
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })
        },
        /**加载岗位数据 */
        loadScreenPlan(){
            this.$httpAES.post("Bestech.CloudPos.KDS.GetKitPostEquipments",{}).then((d)=>{
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.KitPostEquipments=d.ResponseBody;
                    if(this.posEquipmentList.length>0){
                        this.KitPostEquipments?.forEach(it=>{
                            it.KdsProjects.forEach(kds=>{
                                let eq=this.posEquipmentList.find(eq=>eq.Equipment_ID==kds.Equipment_ID);
                                if(eq){
                                    kds.Equipment_Name=eq.Equipment_Name;
                                }
                            })
                        })
                    }
                }
            }).catch(e=>{
                this.$message.error('加载岗位数据失败：'+e.message);
                console.log('加载岗位数据失败：',e);
            })
        },
        /**合并 菜品 自定义套餐 自定义菜品数据 */
        setPosFoods(){
            if(this.foodMainCategory!=null && this.PosDiyFoodsData!=null){
                let data=this.foodMainCategory;
                let diyCategory={
                    MainCategory_ID:newGuid(),
                    MainCategory_Code:"",
                    MainCategory_Name:"自定义",
                    FoodSubCategorys:[]
                };
                if(this.PosDiyFoodsData && this.PosDiyFoodsData.length>0){//合并 自定义菜品
                    this.PosDiyFoodsData.forEach(diy=>{
                        let isBo=data.some(category=>category?.FoodSubCategorys?.some(sub=>{
                                if(diy.SubCategory_ID==sub.SubCategory_ID && diy.PosFoods?.length>0){//合并自定义菜品
                                    sub.PosFoods=sub.PosFoods.concat(diy.PosFoods);
                                    return true;
                                }
                                return false
                            }
                        ))

                        if(!isBo){//未找到自定义菜品对应的分类
                            diyCategory.FoodSubCategorys.push(diy);
                        }
                    })
                }

                if(this.$cacheData?.globalVariable?.GlobalSysSetting?.DiyTcInfo){//自定义套餐
                    let info=this.$cacheData?.globalVariable?.GlobalSysSetting?.DiyTcInfo;
                    let diyTcInfo={
                        Food_ID:info.EAT_XFBMID,
                        Food_Code:info.EAT_XFCODE,
                        Food_Name:info.EAT_XFNAME,
                        Food_PinYin:info.PINYIN,
                        Food_Price:info.EAT_XFPRICE,
                        Food_Size:info.EAT_XFSIZE,
                        KitPlace_ID:info.KITPLACE_AUTOID,
                        SubCategory_ID:info.EAT_KINDID
                    }
                    let isBo=data.some(category=>
                        category?.FoodSubCategorys?.some(sub=>{
                            if(sub.SubCategory_ID==diyTcInfo.SubCategory_ID){
                                sub.PosFoods.push(diyTcInfo);
                                return true;
                            }
                            return false;
                        })
                    );
                    if(!isBo){//未找到自定义套餐菜品对应的分类
                        if(!diyTcInfo.SubCategory_ID){
                            diyTcInfo.SubCategory_ID=newGuid()
                        }
                        diyCategory.FoodSubCategorys.push({
                            SubCategory_ID:diyTcInfo.SubCategory_ID,
                            SubCategory_Name:"自定义套餐",
                            SubCategory_Code:"",
                            PosFoods:[diyTcInfo]
                        });
                    }
                }

                if(diyCategory.FoodSubCategorys.length>0){
                    data.push(diyCategory);
                }
                let foods=[];
                data?.forEach(category=>{
                    category.FoodSubCategorys?.forEach(sub=>{
                        let posFoods=[];
                        sub.PosFoods.forEach(t=>{
                            //t.NewKitPlace_ID=t.KitPlace_ID;
                            posFoods.push(t);
                            if(t.MultipleSizeFoods?.length>0){//多规格
                                t.MultipleSizeFoods.forEach(it=>{
                                    if(it.Food_ID!=t.Food_ID){
                                        //it.NewKitPlace_ID=it.KitPlace_ID;
                                        posFoods.push(it);
                                    }
                                })
                            }
                        })
                        sub.PosFoods=posFoods;
                        foods=foods.concat(posFoods);
                    });
                })
                this.PosFoods=foods;
            }
        },
        /**上级分类 */
        getCategory(row){
            let name=""
            if(!this.currentNode){
                this.treeData[0].children.find(category=>{
                    let sub=category.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                    if(sub){
                        name=sub.label;
                        return true;
                    }
                    return false;
                })
            }
            else if(this.currentNode?.children){
                let sub=this.currentNode?.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                if(sub){
                    name=sub.label;
                }
            }else{
                name=this.currentNode.label;
            }
            return name;
        },
        /**出单位置数据*/
        getKitIssuePlace(row){
            let kit=this.KitIssuePlace?.find(t=>t.IssuePlace_AutoID==row.KitPlace_ID);
            return kit?.IssuePlace_Name||"";
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        /**修改时 勾选行 */
        rowSelect(row){
            this.$refs.tableEl?.toggleRowSelection(row,true);
        },
        /**批量修改 出单位置 */
        kitBatch(item){
            this.pageTableData?.forEach(row=>{
                this.editFoodList[row.Food_ID]=item.IssuePlace_AutoID;
                this.rowSelect(row);
            });
        },
        /**保存修改 */
        saveData(){
            if(this.showType==0){
                this.SetEatKitPlace();
            }else if(this.showType==1){
                this.$refs.itemOnPrint?.save();
            }else if(this.showType==2){
                this.$refs.itemOnKds?.save();
            }else if(this.showType==3){
                this.$refs.kdsOnItem?.save();
            }
        },
        /**保存 菜品出单位置数据 */
        SetEatKitPlace(){
            if(!this.checkFoods?.length>0){
                this.$message.warning('请先勾选要保存的数据');
                return;
            }
            let EatXfbmList=[]
            this.checkFoods?.forEach(t=>{
                EatXfbmList.push({
                    EAT_XFBMID:t.Food_ID,
                    Food_Name:t.Food_Name,
                    KITPLACE_AUTOID:this.editFoodList[t.Food_ID]
                });
            })
            if(EatXfbmList.length==0){
                this.$message.warning('数据无修改！请先修改在进行保存');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SetEatKitPlace",{
                EatXfbmList:EatXfbmList,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    this.checkFoods?.forEach(t=>{
                        t.KitPlace_ID=this.editFoodList[t.Food_ID];
                    })
                    this.$refs.tableEl?.clearSelection();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            /**列名 */
            let headers = [
                ["编码","名称","助记码",'单价','单位','菜品等级',"上级分类","出单位置"]
            ]
            // 将数据转化成 excel 所需的数据格式
            let data = this.filterTableData?.map(t=>[t.Food_Code,t.Food_Name,t.Food_PinYin,t.Food_Price,t.Food_Size,t.Kit_Level,this.getCategory(t),this.getKitIssuePlace(t)])||[];
            
            let ws = XLSX.utils.aoa_to_sheet(headers)
            XLSX.utils.sheet_add_aoa(ws, data, { origin: this.beginRow || 'A2' })
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
            try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '菜品出单设置.xlsx')
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e, wbout)
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        dblclick(row,column){
            if(column.property=="Food_Name" && row.SetMealInfo){
                this.showTc(row.SetMealInfo)
            }
        },
        //加载要隐藏的数据
        loadHideFoodIDs(isRefresh){
            if(!isRefresh){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.GetHideFoodIDList",{
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.hideXfIDList=d.ResponseBody;//要隐藏的菜品
                }
            }).catch(e=>{
                loading.close();
                console.log('e:',e)
                this.$alert('获取隐藏菜品失败', "提示", {confirmButtonText: "确定"});
            })
        },
        hideClick(){
            let checkFoods=this.checkFoods;
            if(this.showType==1){
                checkFoods= this.$refs.itemOnPrint.checkFoods;
                
            }
            if(!checkFoods?.length>0){
                this.$message.warning('请先勾选要隐藏的数据');
                return;
            }
            let ids=checkFoods?.map(it=>it.Food_ID);

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.SaveXFBMPrintHideList",{
                Operate_Type:0,// 0:新增 1:删除
                PrintHideXfIDList:ids,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    if(this.showType==1){
                        this.$refs.itemOnPrint?.$refs?.elTable?.clearSelection();
                    }else{
                        this.$refs.elTable?.clearSelection();
                    }
                    

                    if(this.hideXfIDList?.length>0){
                        this.hideXfIDList=this.hideXfIDList.concat(ids);
                    }else{
                        this.hideXfIDList=ids;
                    }
                    
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        },
        /**查看隐藏菜品 */
        showClick(){
            this.itemHideIsShow=true;
        }
    }
}
</script>

<style lang="scss">
@import './eatKitPlaceSet.scss'
</style>