<template>
    <modal class="itemHideModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">隐藏菜品</div>
        <div class="modal-body">
            <div class="table-box grey-table" v-table-el-height="'tableEl'">
                <el-table class="el-table--scrollable-y" height="530" style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                    :data="pageTableData"
                    highlight-current-row
                    @selection-change="handleSelectionChange"
                    ref="table1"
                >
                    <el-table-column type="selection" label="选择" width="60" ></el-table-column>
                    <el-table-column prop="Food_Code" label="编码" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Food_Name" label="名称" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Food_Size" label="单位" min-width="80" align="left"></el-table-column>
                </el-table>
            </div>
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                        isBlurClose
                ></key-board-input>
                <div class="float-right">
                    <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                    <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                    <div class="page-bnt">
                        <el-select v-model="pageSize"  >
                            <el-option :value="20" label="20条/页"></el-option>
                            <el-option :value="30" label="30条/页"></el-option>
                            <el-option :value="50" label="50条/页"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
            <button class="btn btn-confirm" @click="confirm()">取消隐藏</button>
        </div>
    </modal>
</template>

<script>
//查看 隐藏菜品
export default {
    name:'itemHideModel',
    emits:["close","updateItem"],
    props:{
        isShow:Boolean,
        hideItem:{
            typeof:Object,
            default:null
        }
    },
    computed:{
        filterTableData(){
            let data=this.hideItem||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterTableData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.hideItem?.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        },
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        pageSize(newVal){
            this.$cacheData.set("itemHide_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
    },
    data(){
        let pageSize= this.$cacheData.get("itemHide_pageSize")||20;
        return{
            /**检索 */
            searchTxt:"",
            tablePageIndex:0,
            pageSize:pageSize,
            /**选中的数据 */
            multipleSelection: []
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
   
    methods:{
        /**初始化数据 */
        init(){
            this.searchTxt="";
            this.tablePageIndex=0;
            this.multipleSelection=[];
        },
        hide(){
            this.$emit("close");
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**纪录选中的数据 */
        handleSelectionChange(val){
            this.multipleSelection=val;
        },
        confirm(){
            if(!this.multipleSelection?.length>0){
                this.$message.warning('请先勾选要取消隐藏的数据');
                return;
            }
            let ids=this.multipleSelection.map(it=>it.Food_ID);

            const loading = this.$loading({
                text: "数据取消隐藏中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.SaveXFBMPrintHideList",{
                Operate_Type:1,// 删除
                PrintHideXfIDList:ids,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据取消隐藏成功");
                    this.$refs.table1?.clearSelection();
                    let hideIds=this.hideItem?.filter(it=>ids.indexOf(it.Food_ID)<0)?.map(it=>it.Food_ID)
                    this.$emit("updateItem",hideIds);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据取消隐藏失败："+e);
                console.log("数据取消隐藏失败："+e);
            })
            
        }
    }
}
</script>

<style lang="scss">
@import './itemHideModel.scss'
</style>